import React from 'react'
import { StaticImage } from 'gatsby-plugin-image'


export default function Footer() {
  return (
    <footer 
    // className='flex flex-col text-center px-6 py-4 border-t border-bej'
    className='mt-6'
    >
        {/* <StaticImage objectFit='' className=' self-center text-center w-[60%] h-[60%] md:h-[20%] md:w-[20%]' src="../images/isbank-logo.png" alt="is-bankasi-logo"/>
        <p className='my-4'>Türkiye İş Bankası Garantörlüğü İle</p> */}
    </footer>
  )
}